import DialogView from "@/components/DialogView";
import BatterTableDataView from "@/components/BatterTableDataView";
import api from "@/api";
export default {
  name: "EmdEnumValueListView",
  components: {
    DialogView,
    BatterTableDataView
  },
  props: {
    title: {
      type: String,
      default: null
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    sort: {
      type: Boolean,
      default: false
    },
    addBtn: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: null
    },
    initQuery: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      createVisible: false,
      switches: {}
    };
  },
  methods: {
    addSortIndex(data, offset) {
      api.updateEnumValueSortIndex({
        id: data.id,
        offset: offset
      }).then(() => {
        this.$refs.enumValueTable.fetchData();
      });
    },
    onRowSelectChange(d) {
      this.$emit('onRowSelectChange', d);
    },
    createEnumValue(data) {
      for (let k of Object.keys(this.initQuery)) {
        if (!data[k]) {
          data[k] = this.initQuery[k];
        }
      }
      data.type = this.type;
      api.createEnumValue(data).then(() => {
        this.createVisible = false;
        this.$refs.enumValueTable.fetchData();
      });
    },
    updateEnableState(dataId, value) {
      api.updateEnumValueEnableState(dataId, value).then(() => {
        this.switches[dataId] = value;
        this.$refs.enumValueTable.fetchData();
      });
    },
    onFetchData(data) {
      this.$emit('fetch', data);
      let list = data['list'];
      for (let l of list) {
        this.switches[l['id']] = l['enable'];
      }
    }
  }
};