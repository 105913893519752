import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6f4b6950"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "grid-content"
};
const _hoisted_2 = ["id"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_col = _resolveComponent("el-col");
  return _openBlock(), _createBlock(_component_el_col, {
    span: 24,
    style: {
      "margin": "10px 0"
    }
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_row, null, {
      default: _withCtx(() => [_createElementVNode("div", {
        id: $props.id,
        style: {
          "width": "100%",
          "height": "200px"
        },
        ref: "canvas"
      }, null, 8, _hoisted_2)]),
      _: 1
    })])]),
    _: 1
  });
}