import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7ea3a670"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "CommonHtmlEditorViewContainer wd100 pd12 box"
};
const _hoisted_2 = {
  class: "ft30 boldFont"
};
const _hoisted_3 = {
  class: "bothCenter wd100 mt12"
};
const _hoisted_4 = {
  ref: "aiEditor",
  class: "wbg pd12 box wd100",
  style: {
    "height": "calc(100vh - 78px)",
    "width": "850px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, {
    class: "bothCenter"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 3,
      class: "bothCenter"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_button, {
        style: {
          "width": "100px"
        },
        onClick: _cache[0] || (_cache[0] = $event => _ctx.$router.go(-1))
      }, {
        default: _withCtx(() => [_createTextVNode("返回")]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 18,
      class: "bothCenter"
    }, {
      default: _withCtx(() => [_createElementVNode("span", _hoisted_2, _toDisplayString($data.title), 1)]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 3,
      class: "bothCenter"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_button, {
        type: "primary",
        style: {
          "width": "100px"
        },
        onClick: _cache[1] || (_cache[1] = $event => $options.save(false))
      }, {
        default: _withCtx(() => [_createTextVNode("保存 SAVE")]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, null, 512)])]);
}