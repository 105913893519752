import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-e44490b6"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "wd100"
};
const _hoisted_2 = {
  class: "pageTitle padding20"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
  class: "bothCenter"
};
const _hoisted_5 = ["onClick"];
const _hoisted_6 = {
  class: "ml12 mr12"
};
const _hoisted_7 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_divider = _resolveComponent("el-divider");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_switch = _resolveComponent("el-switch");
  const _component_BatterTableDataView = _resolveComponent("BatterTableDataView");
  const _component_DialogView = _resolveComponent("DialogView");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [$props.showTitle ? (_openBlock(), _createBlock(_component_el_row, {
    key: 0
  }, {
    default: _withCtx(() => [_createElementVNode("span", _hoisted_2, _toDisplayString($props.title), 1), _createVNode(_component_el_divider, {
      class: "padding0 margin0"
    })]),
    _: 1
  })) : _createCommentVNode("", true), _createVNode(_component_el_row, {
    class: "pd12"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_button, {
      class: "primaryBtn",
      icon: 'plus',
      onClick: _cache[0] || (_cache[0] = $event => {
        $data.createVisible = true;
      })
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString($props.addBtn ? $props.addBtn : '添加' + $props.title), 1)]),
      _: 1
    }), _renderSlot(_ctx.$slots, "default", {}, undefined, true)]),
    _: 3
  }), _createVNode(_component_el_row, {
    class: "pd12"
  }, {
    default: _withCtx(() => [(_openBlock(), _createBlock(_component_BatterTableDataView, {
      "table-id": 11,
      "init-query": $props.initQuery,
      key: $props.type,
      onOnRowSelectChange: $options.onRowSelectChange,
      ref: "enumValueTable",
      onOnFetchData: $options.onFetchData
    }, {
      enable: _withCtx(data => [_createVNode(_component_el_switch, {
        "model-value": $data.switches[data.rowId],
        onChange: val => $options.updateEnableState(data.rowId, val),
        class: "ml-2",
        style: {
          "--el-switch-on-color": "#303133",
          "--el-switch-off-color": "lightgray"
        }
      }, null, 8, ["model-value", "onChange"])]),
      refCnt: _withCtx(data => [_createElementVNode("span", {
        style: {
          "color": "#007ED9"
        },
        class: "ptr",
        onClick: $event => _ctx.$emit('showRefCnt', data.rowId)
      }, _toDisplayString(data.data.extra.refCnt), 9, _hoisted_3)]),
      extra: _withCtx(data => [_renderSlot(_ctx.$slots, "extra", {
        data: data.data
      }, undefined, true)]),
      label: _withCtx(data => [_renderSlot(_ctx.$slots, "label", {
        data: data.data
      }, undefined, true)]),
      sortIndex: _withCtx(data => [_createElementVNode("div", _hoisted_4, [_createElementVNode("span", {
        onClick: $event => $options.addSortIndex(data.data, 1),
        style: {
          "color": "red"
        },
        class: "boldFont ft18 ptr"
      }, "↓", 8, _hoisted_5), _createElementVNode("span", _hoisted_6, _toDisplayString(data.rowData), 1), _createElementVNode("span", {
        onClick: $event => $options.addSortIndex(data.data, -1),
        style: {
          "color": "green"
        },
        class: "boldFont ft18 ptr"
      }, "↑", 8, _hoisted_7)])]),
      _: 3
    }, 8, ["init-query", "onOnRowSelectChange", "onOnFetchData"]))]),
    _: 3
  })]), _createVNode(_component_DialogView, {
    "visible-control": $data.createVisible,
    "data-id": $props.sort ? 3 : 2,
    "bind-id": $props.type,
    onOnDialogClose: _cache[1] || (_cache[1] = $event => $data.createVisible = false),
    onSubmit: $options.createEnumValue
  }, null, 8, ["visible-control", "data-id", "bind-id", "onSubmit"])], 64);
}