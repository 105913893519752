import api from "@/api";
export default {
  name: "MyView",
  data() {
    return {
      myInfo: {
        password: '',
        rePassword: ''
      }
    };
  },
  methods: {
    getMyInfo() {
      api.getMyInfo().then(ret => {
        this.myInfo = ret;
        this.password = '';
        this.rePassword = '';
      });
    },
    updateMyInfo() {
      api.updateMyInfo(this.myInfo).then(() => {
        this.getMyInfo();
      });
    }
  },
  mounted() {
    this.getMyInfo();
  }
};