import {createRouter, createWebHistory} from 'vue-router'
import LoginView from '../views/LoginView.vue'
import HomeView from "@/views/HomeView";
import DashboardView from "@/views/dashboard/DashboardView";
import AdListView from "@/views/system/AdListView";
import MyView from "@/views/system/MyView";
import SellerDashboardView from "@/views/dashboard/SellerDashboardView";
import AdminView from "@/views/system/AdminView";
import PromoterDashboardView from "@/views/dashboard/PromoterDashboardView";
import BannerSettingView from "@/views/system/BannerSettingView.vue";
import SiteConfigView from "@/views/system/SiteConfigView.vue";
import CommonHtmlEditorView from "@/views/richtext/CommonHtmlEditorView.vue";
import CommonHtmlPreviewView from "@/views/richtext/CommonHtmlPreviewView.vue";
import HotSpringConfigView from "@/views/hotspring/HotSpringConfigView.vue";
import HotSpringServeView from "@/views/hotspring/HotSpringServeView.vue";
import GuestRoomConfigView from "@/views/guestroom/GuestRoomConfigView.vue";
import GuestRoomStyleView from "@/views/guestroom/GuestRoomStyleView.vue";
import GuestRoomView from "@/views/guestroom/GuestRoomView.vue";

const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
        redirect: "dashboard",
        children: [
            {
                path: "dashboard",
                component: DashboardView
            },
            {
                path: "sellerDashboard",
                component: SellerDashboardView
            },
            {
                path: 'promoterDashboard',
                component: PromoterDashboardView
            },
            {
                path: 'advertisement',
                component: AdListView
            },
            {
                path: 'my',
                component: MyView
            },
            {
                path: 'admins',
                component: AdminView
            },
            {
                path: '/banner/list',
                component: BannerSettingView,
            },
            {
                path: '/setting/applet',
                component: SiteConfigView,
            },
            {
                path: '/setting/hot/spring',
                component: HotSpringConfigView,
            },
            {
                path: '/hot/spring',
                component: HotSpringServeView,
            },
            {
                path: '/setting/guest/room',
                component: GuestRoomConfigView,
            },
            {
                path: '/setting/guest/room/style',
                component: GuestRoomStyleView,
            },
            {
                path: '/guest/room',
                component: GuestRoomView,
            },
        ],
        meta: {
            auth: true
        }
    },
    {
        path: '/html/edit/:htmlId',
        component: CommonHtmlEditorView,
        meta: {
            auth: true
        }
    },
    {
        path: '/html/preview/:htmlId',
        component: CommonHtmlPreviewView,
        meta: {
            auth: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView,
        meta: {
            auth: false
        }
    },
]


const router = createRouter({
    history: createWebHistory(),
    routes
})
export default router
