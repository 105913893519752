import api from "@/api";
import DataTable from "@/components/DataTable";
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import DynaQueryForm from "@/components/DynaQueryForm";
export default {
  name: "SellerDashboardView",
  components: {
    DataTable,
    DynaQueryForm
  },
  setup() {
    return {
      locale: zhCn
    };
  },
  data() {
    return {
      query: {},
      form: {},
      tableDataLoading: false,
      tableData: {
        title: "...",
        header: [],
        list: [],
        textStyleMapping: {},
        textMapping: {}
      }
    };
  },
  mounted() {
    this.fetchChannelData();
  },
  methods: {
    onResetQuery() {
      this.query = {};
      this.fetchChannelData();
    },
    onQuery(query) {
      this.query = query;
      this.fetchChannelData();
    },
    fetchChannelData() {
      this.tableDataLoading = true;
      api.queryChannelData(this.query).then(ret => {
        this.tableDataLoading = false;
        this.tableData = ret;
      }).catch(() => {
        this.tableDataLoading = false;
      });
    }
  }
};