import "core-js/modules/es.array.push.js";
import { ElMessage } from "element-plus";
import uploader from "@/utils/uploader";
import { AiEditor } from "aieditor";
import api from "@/api";
import "aieditor/dist/style.css";
export default {
  name: "CommonHtmlEditorView",
  components: {},
  props: {},
  data() {
    return {
      htmlId: this.$route.params.htmlId,
      title: null,
      extra: null,
      htmlVersion: null,
      saving: false,
      historyPreview: false,
      drawer: false,
      versions: [],
      editorId: null
    };
  },
  methods: {
    previewHistory(url) {
      this.htmlVersion = url;
      this.historyPreview = true;
    },
    save() {
      if (this.saving) {
        ElMessage.warning('上传中，请稍后再试');
        return;
      }
      this.saving = true;
      ElMessage.info('上传中...');
      uploader.uploadString('html', this.editor.getHtml(), url => {
        console.log('html url', url);
        this.extra.configValue = url;
        ElMessage.success('上传成功');
        api.updateEnumValue({
          id: this.htmlId,
          extra: this.extra
        }).then(() => {
          ElMessage.success('更新成功');
        });
        this.saving = false;
      });
    },
    initEditor(content) {
      this.initEditorWithAiCfg(content, null);
    },
    initEditorWithAiCfg(content, cfg) {
      const fonts = [];
      for (let i = 9; i <= 100; i++) {
        if (i == 16) {
          fonts.push({
            name: '文段标题',
            value: i
          });
        } else if (i == 18) {
          fonts.push({
            name: 'H3',
            value: i
          });
        } else if (i == 24) {
          fonts.push({
            name: 'H2',
            value: i
          });
        } else {
          fonts.push({
            name: i,
            value: i
          });
        }
      }
      this.editor = new AiEditor({
        element: this.$refs.aiEditor,
        ai: cfg,
        fontSize: {
          values: fonts,
          default: 14
        },
        // fontFamily: {
        //   values: [
        //     {name: "宋体", value: "SimSun"},
        //     {name: "仿宋", value: "FangSong"},
        //     {name: "黑体", value: "SimHei"},
        //     {name: "楷体", value: "KaiTi"},
        //     {name: "微软雅黑", value: "Microsoft YaHei"},
        //     {name: "方正仿宋简体_GBK", value: "FangSong_GB2312"},
        //     {name: "Arial", value: "Arial"},
        //     {name: "Times New Roman", value: "Times New Roman"},
        //   ]
        // },
        toolbarKeys: ["undo", "redo", "brush", "eraser", "|", "heading", "font-family", "font-size", "|", "bold", "italic", "underline", "strike", "link", "code", "subscript", "superscript", "hr", "todo", "emoji", "|", "highlight", "font-color", "|", "align", "line-height", "|", "bullet-list", "ordered-list", "indent-decrease", "indent-increase", "break", "|", "image", "video", "attachment", "quote", "code-block", "table", "|", "source-code", "printer", "fullscreen", {
          icon: "<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" fill=\"currentColor\"><path d=\"M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16ZM12 18C8.68629 18 6 15.3137 6 12C6 8.68629 8.68629 6 12 6C15.3137 6 18 8.68629 18 12C18 15.3137 15.3137 18 12 18ZM12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14Z\"></path></svg>",
          onClick: (event, editor) => {
            editor.insert('$DOMAIN$');
          },
          tip: "插入站点超链关键字"
        }, {
          icon: "<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" fill=\"currentColor\"><path d=\"M3 3C2.44772 3 2 3.44772 2 4V20C2 20.5523 2.44772 21 3 21H21C21.5523 21 22 20.5523 22 20V4C22 3.44772 21.5523 3 21 3H3ZM11 5V8H4V5H11ZM4 14V10H11V14H4ZM4 16H11V19H4V16ZM13 16H20V19H13V16ZM20 14H13V10H20V14ZM20 5V8H13V5H20Z\"></path></svg>",
          onClick: (event, editor) => {
            // 创建一个临时的 DOM 元素来解析 HTML 字符串
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = editor.getHtml();
            // 获取表格中的第一行，来计算列的总数（包含 colspan 的情况）
            const firstRow = tempDiv.querySelector('tr');
            if (!firstRow) {
              // 如果没有找到表格的行
              return;
            }
            // 计算总列数
            let totalCols = 0;
            firstRow.querySelectorAll('td, th').forEach(cell => {
              const colspan = parseInt(cell.getAttribute('colspan')) || 1;
              totalCols += colspan;
            });
            console.log('共有', totalCols, '列');
            // 计算每列的宽度百分比
            const colWidth = 1.0 / totalCols * 800;
            console.log('每列', colWidth, 'px');
            // 查找所有 colspan="1" 的 td 和 th 元素，并设置 colwidth
            tempDiv.querySelectorAll('td[colspan="1"], th[colspan="1"]').forEach(element => {
              element.setAttribute('colwidth', colWidth);
            });
            // 返回修改后的 HTML 字符串
            editor.setContent(tempDiv.innerHTML);
          },
          tip: "一键均分表格宽度"
        }],
        textSelectionBubbleMenu: {
          enable: true,
          items: ["ai", "Bold", "Italic", "Underline", "Strike", "code", {
            id: 'yijianwenduanjiacu',
            title: '一键文段加粗',
            icon: "<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" fill=\"currentColor\"><path d=\"M15.2238 15.5079L13.0111 20.1581C12.8687 20.4573 12.5107 20.5844 12.2115 20.442C12.1448 20.4103 12.0845 20.3665 12.0337 20.3129L8.49229 16.5741C8.39749 16.474 8.27113 16.4096 8.13445 16.3918L3.02816 15.7243C2.69958 15.6814 2.46804 15.3802 2.51099 15.0516C2.52056 14.9784 2.54359 14.9075 2.5789 14.8426L5.04031 10.3192C5.1062 10.1981 5.12839 10.058 5.10314 9.92253L4.16 4.85991C4.09931 4.53414 4.3142 4.22086 4.63997 4.16017C4.7126 4.14664 4.78711 4.14664 4.85974 4.16017L9.92237 5.10331C10.0579 5.12855 10.198 5.10637 10.319 5.04048L14.8424 2.57907C15.1335 2.42068 15.4979 2.52825 15.6562 2.81931C15.6916 2.88421 15.7146 2.95507 15.7241 3.02833L16.3916 8.13462C16.4095 8.2713 16.4739 8.39766 16.5739 8.49245L20.3127 12.0338C20.5533 12.2617 20.5636 12.6415 20.3357 12.8821C20.2849 12.9357 20.2246 12.9795 20.1579 13.0112L15.5078 15.224C15.3833 15.2832 15.283 15.3835 15.2238 15.5079ZM16.0206 17.435L17.4348 16.0208L21.6775 20.2634L20.2633 21.6776L16.0206 17.435Z\"></path></svg>",
            onClick(editor) {
              const p = editor.getSelectedText();
              editor.insert(`<span style="font-family: SimHei; font-size: 16px"><strong>${p}</strong></span>`);
            }
          }]
        },
        placeholder: "点击输入内容...",
        content: content,
        image: {
          allowBase64: false,
          defaultSize: 350,
          uploader: (file, uploadUrl, headers, formName) => {
            console.log('upload image', file, uploadUrl, headers, formName);
            return new Promise((resolve, reject) => {
              uploader.uploadFile(file, url => {
                resolve({
                  "errorCode": 0,
                  "data": {
                    "src": url
                  }
                });
              }, error => {
                reject(error);
              });
            });
          }
        },
        video: {
          uploader: (file, uploadUrl, headers, formName) => {
            console.log('upload video', file, uploadUrl, headers, formName);
            return new Promise((resolve, reject) => {
              uploader.uploadFile(file, url => {
                resolve({
                  "errorCode": 0,
                  "data": {
                    "src": url,
                    "poster": url + "?x-oss-process=video/snapshot,t_0"
                  }
                });
              }, error => {
                reject(error);
              });
            });
          }
        },
        attachment: {
          uploader: (file, uploadUrl, headers, formName) => {
            console.log('upload attachment', file, uploadUrl, headers, formName);
            return new Promise((resolve, reject) => {
              uploader.uploadFile(file, url => {
                resolve({
                  "errorCode": 0,
                  "data": {
                    "href": url,
                    "fileName": file.name
                  }
                });
              }, error => {
                reject(error);
              });
            });
          }
        }
      });
    },
    queryStyleAndSet() {
      this.$refs.aiEditor.style.setProperty('--table-deep-bg-color', '#9595A7');
      this.$refs.aiEditor.style.setProperty('--table-light-bg-color', '#f2f2f8');
    },
    init() {
      let thiz = this;
      api.getEnumValueById(this.htmlId).then(ret => {
        this.title = ret.label;
        this.extra = ret.extra;
        if (ret.extra.configValue) {
          api.get(ret.extra.configValue, {
            ice_plain: true
          }).then(ctn => {
            thiz.initEditor(ctn);
          }).catch(() => {
            thiz.initEditor('读取历史版本失败!');
          });
        } else {
          thiz.initEditor('');
        }
      });
    }
  },
  mounted() {
    this.init();
  }
};