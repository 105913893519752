import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-b3b8e822"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "CommonHtmlPreviewViewContainer wd100 pd12 box"
};
const _hoisted_2 = {
  style: {
    "width": "900px"
  }
};
const _hoisted_3 = {
  class: "light"
};
const _hoisted_4 = {
  class: "leftCenter mt8 brd6 pd12 box",
  style: {
    "width": "900px",
    "background": "#eaeaea"
  }
};
const _hoisted_5 = {
  class: "ml16"
};
const _hoisted_6 = {
  ref: "renderArea",
  class: "bothCenter mt8 colFlex wd100 mt12"
};
const _hoisted_7 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_tag = _resolveComponent("el-tag");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, {
    class: "bothCenter"
  }, {
    default: _withCtx(() => [_createElementVNode("h1", null, _toDisplayString($data.title), 1)]),
    _: 1
  }), $data.page ? (_openBlock(), _createBlock(_component_el_row, {
    key: 0,
    class: "colFlex bothCenter ft14"
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("span", _hoisted_3, _toDisplayString($data.page.updatedTime) + " | 编辑：" + _toDisplayString($data.page.author) + " | 阅读：" + _toDisplayString($data.page.fakeReadCnt + $data.page.readCnt), 1)]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_el_tag, {
      type: "primary"
    }, {
      default: _withCtx(() => [_createTextVNode("摘要")]),
      _: 1
    }), _createElementVNode("span", _hoisted_5, _toDisplayString($data.page.description), 1)])]),
    _: 1
  })) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", {
    class: "wbg pd12 box wd100",
    id: "content-render",
    innerHTML: $data.html,
    style: {
      "width": "900px"
    }
  }, null, 8, _hoisted_7)], 512)]);
}