import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1c3bef48"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "HotSpringServeViewContainer"
};
const _hoisted_2 = {
  style: {
    "display": "flex",
    "flex-wrap": "wrap"
  }
};
const _hoisted_3 = {
  class: "colFlex bothCenter"
};
const _hoisted_4 = {
  class: "mb12"
};
const _hoisted_5 = {
  class: "colFlex bothCenter"
};
const _hoisted_6 = {
  class: "mb12"
};
const _hoisted_7 = {
  class: "colFlex bothCenter"
};
const _hoisted_8 = {
  style: {
    "text-decoration-line": "line-through"
  }
};
const _hoisted_9 = {
  style: {
    "font-weight": "bold",
    "color": "orange"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_image = _resolveComponent("el-image");
  const _component_el_tag = _resolveComponent("el-tag");
  const _component_el_button = _resolveComponent("el-button");
  const _component_BatterTableDataView = _resolveComponent("BatterTableDataView");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_BatterTableDataView, {
    "table-id": 3
  }, {
    img: _withCtx(data => [_createVNode(_component_el_image, {
      "preview-teleported": true,
      class: "ptr",
      style: {
        "width": "auto",
        "height": "100px"
      },
      src: data.rowData,
      "preview-src-list": [data.rowData],
      "initial-index": 0,
      fit: "cover"
    }, null, 8, ["src", "preview-src-list"])]),
    labels: _withCtx(data => [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.rowData, l => {
      return _openBlock(), _createBlock(_component_el_tag, {
        key: l.id,
        type: "primary",
        class: "mr12 mb12"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(l.label), 1)]),
        _: 2
      }, 1024);
    }), 128))])]),
    state: _withCtx(data => [_createElementVNode("span", null, _toDisplayString(data.mapping[data.rowData]), 1)]),
    buyNoticeTextId: _withCtx(data => [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_el_button, {
      size: "small",
      onClick: $event => $options.gotoEdit(data.rowData),
      type: "primary"
    }, {
      default: _withCtx(() => [_createTextVNode("编辑")]),
      _: 2
    }, 1032, ["onClick"])]), _createVNode(_component_el_button, {
      size: "small",
      onClick: $event => $options.previewHtml(data.rowData)
    }, {
      default: _withCtx(() => [_createTextVNode("查看")]),
      _: 2
    }, 1032, ["onClick"])])]),
    descriptionTextId: _withCtx(data => [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createVNode(_component_el_button, {
      size: "small",
      onClick: $event => $options.gotoEdit(data.rowData),
      type: "primary"
    }, {
      default: _withCtx(() => [_createTextVNode("编辑")]),
      _: 2
    }, 1032, ["onClick"])]), _createVNode(_component_el_button, {
      size: "small",
      onClick: $event => $options.previewHtml(data.rowData)
    }, {
      default: _withCtx(() => [_createTextVNode("查看")]),
      _: 2
    }, 1032, ["onClick"])])]),
    price: _withCtx(data => [_createElementVNode("div", _hoisted_7, [_createElementVNode("span", _hoisted_8, _toDisplayString((data.data.originalPrice / 100.0).toFixed(2)), 1), _createElementVNode("span", _hoisted_9, _toDisplayString((data.data.price / 100.0).toFixed(2)), 1)])]),
    _: 1
  })]);
}