import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-314d082c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "GuestRoomViewContainer"
};
const _hoisted_2 = {
  style: {
    "display": "flex",
    "flex-wrap": "wrap"
  }
};
const _hoisted_3 = {
  key: 0,
  src: "https://cdn.waityou.online/a35d3c11-2b2d-70d7-159d-78486f7b6a9d.svg",
  class: "sqr22",
  alt: "促销"
};
const _hoisted_4 = {
  key: 1,
  src: "https://cdn.waityou.online/2f2ee8e3-4da4-b794-a54a-19f97116081f.svg",
  class: "ml12 sqr22",
  alt: "送好礼"
};
const _hoisted_5 = {
  class: "colFlex bothCenter"
};
const _hoisted_6 = {
  style: {
    "text-decoration-line": "line-through"
  }
};
const _hoisted_7 = {
  style: {
    "font-weight": "bold",
    "color": "orange"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_image = _resolveComponent("el-image");
  const _component_el_tag = _resolveComponent("el-tag");
  const _component_BatterTableDataView = _resolveComponent("BatterTableDataView");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_BatterTableDataView, {
    "table-id": 6
  }, {
    imgs: _withCtx(data => [_createVNode(_component_el_image, {
      "preview-teleported": true,
      class: "ptr",
      style: {
        "width": "auto",
        "height": "100px"
      },
      src: data.rowData[0],
      "preview-src-list": data.rowData,
      "initial-index": 0,
      fit: "cover"
    }, null, 8, ["src", "preview-src-list"])]),
    labels: _withCtx(data => [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.rowData, l => {
      return _openBlock(), _createBlock(_component_el_tag, {
        key: l.id,
        type: "primary",
        class: "mr12 mb12"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(l.label), 1)]),
        _: 2
      }, 1024);
    }), 128))]), data.data.coupon ? (_openBlock(), _createElementBlock("img", _hoisted_3)) : _createCommentVNode("", true), data.data.gift ? (_openBlock(), _createElementBlock("img", _hoisted_4)) : _createCommentVNode("", true)]),
    state: _withCtx(data => [_createElementVNode("span", null, _toDisplayString(data.mapping[data.rowData]), 1)]),
    price: _withCtx(data => [_createElementVNode("div", _hoisted_5, [_createElementVNode("span", _hoisted_6, _toDisplayString((data.data.originalPrice / 100.0).toFixed(2)), 1), _createElementVNode("span", _hoisted_7, _toDisplayString((data.data.price / 100.0).toFixed(2)), 1)])]),
    _: 1
  })]);
}