import api from "@/api";
import TableDataView from "@/components/TableDataView";
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import PieChart from "@/components/dashboard/PieChart";
export default {
  name: "PromoterDashboardView",
  components: {
    TableDataView,
    PieChart
  },
  setup() {
    return {
      locale: zhCn
    };
  },
  data() {
    return {
      info: {},
      charts: [],
      statistics: [],
      listFun: api.queryChannelData
    };
  },
  methods: {
    onQueryPromotionStatistic(data) {
      data['organizationUserId'] = -1;
      api.promoterPromotionStatisticQuery(data).then(ret => {
        this.charts = ret.charts;
        this.statistics = ret.statistics;
      });
    }
  }
};