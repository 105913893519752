import api from "@/api";
import {ElMessage} from "element-plus";

export default {
    getFileExtension(file) {
        if (!file || !file.name) {
            return '';
        }
        const fileName = file.name;
        const lastDotIndex = fileName.lastIndexOf('.');

        // 判断点是否存在且不是文件名的第一个字符
        if (lastDotIndex > 0 && lastDotIndex < fileName.length - 1) {
            return '.'+fileName.substring(lastDotIndex + 1).toLowerCase();
        }
        return '';
    },
    uploadString(fileExt, str, callback) {
        let OSS = require('ali-oss');
        api.getOssKeySecret().then(cfg => {
            let ossClient = new OSS({
                region: cfg.region,
                accessKeyId: cfg.accessKeyId,
                accessKeySecret: cfg.accessKeySecret,
                stsToken: cfg.securityToken,
                bucket: cfg.bucket,
                endpoint: cfg.endpoint,
                secure: true
            });
            let ossDomain = cfg.domain;
            let fileName = (cfg.folder ? cfg.folder + '/' : 'user-upload/') + this.guid() + '.' + fileExt;
            ossClient.put(fileName, new Blob([str], {type: 'text/plain'}));
            callback(ossDomain + '/' + fileName)
        })
    },
    uploadBlob(blobData, callback) {
        let OSS = require('ali-oss');
        api.getOssKeySecret().then(cfg => {
            let ossClient = new OSS({
                region: cfg.region,
                accessKeyId: cfg.accessKeyId,
                accessKeySecret: cfg.accessKeySecret,
                stsToken: cfg.securityToken,
                bucket: cfg.bucket,
                endpoint: cfg.endpoint,
                secure: true
            });
            let ossDomain = cfg.domain;
            let fileName = (cfg.folder ? cfg.folder + '/' : 'user-upload/') + this.guid();
            ossClient.put(fileName, blobData);
            callback(ossDomain + '/' + fileName)
        })
    },
    uploadFile(file, callback, failCallback) {
        let OSS = require('ali-oss');
        api.getOssKeySecret().then(cfg => {
            let ossClient = new OSS({
                region: cfg.region,
                accessKeyId: cfg.accessKeyId,
                accessKeySecret: cfg.accessKeySecret,
                stsToken: cfg.securityToken,
                bucket: cfg.bucket,
                endpoint: cfg.endpoint,
                secure: true
            });
            let ossDomain = cfg.domain;
            let fileName = (cfg.folder ? cfg.folder + '/' : 'user-upload/') + this.guid()+this.getFileExtension(file);
            ElMessage.info('上传中...')
            ossClient.put(fileName, file)
                .then(res => {
                    if (res.res.statusCode === 200) {
                        ElMessage.success('上传成功')
                        callback(ossDomain + '/' + fileName)
                    } else {
                        console.log('上传失败', file)
                    }
                }).catch(error => {
                failCallback(error)
            })
        })
    },
    guid() {
        return this.getCurrentTimeHHMMSS()+'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0,
                v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    },
    getCurrentTimeHHMMSS() {
        const now = new Date();
        // 获取小时、分钟、秒
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        // 返回HHMMSS格式
        return hours + minutes + seconds;
    }
}