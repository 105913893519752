import api from "@/api";
import DataTable from "@/components/DataTable";
import DialogView from "@/components/DialogView";
import { ElConfigProvider } from 'element-plus';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
export default {
  name: "AdListView",
  components: {
    ElConfigProvider,
    DialogView,
    DataTable
  },
  setup() {
    return {
      locale: zhCn
    };
  },
  methods: {
    deleteAd(adId) {
      api.deleteAd(adId).then(() => {
        this.fetchTableData();
      });
    },
    updateAd(data) {
      data.id = this.editBindDataId;
      api.updateAd(data).then(() => {
        this.editDialogVisible = false;
        this.editBindDataId = 0;
        this.editBindDataId = 0;
        this.fetchTableData();
      });
    },
    createAd(data) {
      api.createAd(data).then(() => {
        this.createDialogVisible = false;
        this.createDialogDataId = 0;
        this.fetchTableData();
      });
    },
    gotoPage(e) {
      this.page = e;
      this.fetchUserData();
    },
    onSizeChange(e) {
      this.page = 1;
      this.pageSize = e;
      this.fetchUserData();
    },
    fetchTableData() {
      this.tableDataLoading = true;
      this.query['page'] = this.page - 1;
      this.query['pageSize'] = this.pageSize;
      api.getAdTableData(this.query).then(ret => {
        this.tableDataLoading = false;
        this.tableData = ret;
        this.total = ret.total;
        this.page = ret.page + 1;
        this.pageSize = ret.pageSize;
      });
    }
  },
  data() {
    return {
      editDialogVisible: false,
      editDialogDataId: 0,
      editBindDataId: 0,
      detailDialogVisible: false,
      detailBindDataId: 0,
      detailDialogDataId: 0,
      createDialogVisible: false,
      createDialogDataId: 0,
      query: {},
      form: {},
      total: 0,
      page: 1,
      pageSize: 10,
      tableDataLoading: false,
      tableData: {
        title: "...",
        header: [],
        list: [],
        textStyleMapping: {},
        textMapping: {}
      }
    };
  },
  mounted() {
    this.fetchTableData();
  }
};