export default {
  name: "DashboardView",
  components: {},
  data() {
    return {
      pubToday: {},
      pubYesterday: {},
      corpToday: {},
      corpYesterday: {},
      charts: []
    };
  },
  methods: {},
  mounted() {}
};