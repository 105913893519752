const base = {
    baseUrl: "",
    menus: "/api/manager/menu/menuList",
    userInfo: "/api/manager/info",
    loginCaptchaToken: "/login/captchaToken",
    login: "/login/accountLogin",
    logout: "/api/manager/logout?channel=0",
    getOssKeySecret: "/api/manager/oss/getOssKeySecret",
    refreshToken: "/api/manager/wx/refreshToken",
    listMessage: '/api/manager/news/list',
    createMessage: '/api/manager/news/create',
    updateMessage: '/api/manager/news/update',
    deleteMessage: '/api/manager/news/delete?messageId={0}',
    messageDetail: '/api/manager/news/detail?mainId={0}',
    adList: '/api/manager/ad/list',
    createAd: '/api/manager/ad/create',
    updateAd: '/api/manager/ad/update',
    deleteAd: '/api/manager/ad/delete?adId={0}',
    getDashboardData: '/api/manager/statistic/dashboardData',
    getChannelData: '/api/manager/channel/getChannelData',
    queryChannelData: '/api/manager/channel/queryChannelData',
    getMyInfo: '/api/manager/myInfo',
    updateMyInfo: '/api/manager/updateMyInfo',
    createManager: '/api/manager/admin/createManager',
    updateManager: '/api/manager/admin/updateManager',
    deleteManager: '/api/manager/admin/deleteManager?adminId={0}',
    getManagerListData: '/api/manager/admin/list',
    updateEnumValueEnableState: '/api/manager/enum/updateEnumValueEnableState?id={0}&value={1}',
    updateEnumValueSortIndex: '/api/manager/enum/updateEnumValueSortIndex',
    getEnumOptionList: '/api/manager/enum/list4select/{0}',
    getEnumValueById: '/api/manager/enum/getEnumValueById?id={0}',
    updateEnumValue: '/api/manager/enum/update',
    createEnumValue: '/api/manager/enum/create',
    updateBannerEnableState: '/api/manager/setting/mobile/updateBannerEnableState?id={0}',
    getConfigList: '/api/manager/setting/getConfigList',
    createOrUpdateBaseConfig: '/api/manager/setting/createOrUpdateBaseConfig',

    queryForm: {
        1: '/api/manager/hot/spring/getQueryForm',
        2: '/api/manager/guest/room/style/getQueryForm',
        3: '/api/manager/guest/room/getQueryForm',
    },
    dialogs: {
        1: '/api/manager/user/getCreateSellerForm',
        2: '/api/manager/enum/getCreateForm?type={0}',
        3: '/api/manager/enum/getCreateForm?type={0}&sort=true',
        4: '/api/manager/hot/spring/getCreateForm',
        5: '/api/manager/hot/spring/getEditForm?id={0}',
        6: '/api/manager/guest/room/style/getCreateForm',
        7: '/api/manager/guest/room/style/getEditForm?id={0}',
        8: '/api/manager/guest/room/getCreateForm',
        9: '/api/manager/guest/room/getEditForm?id={0}',

        15: '/api/manager/ad/getCreateForm',
        16: '/api/manager/ad/detail?adId={0}',
        17: '/api/manager/ad/getEditForm?adId={0}',
        37: '/api/manager/admin/getCreateAdminForm',
        38: '/api/manager/admin/getAdminEditForm?adminId={0}',
        39: '/api/manager/video/getEditForm?videoId={0}',
        60: '/api/manager/enum/getUpdateForm?id={0}',
        61: '/api/manager/enum/getCreateForm?type={0}',
        64: '/api/manager/setting/mobile/getBannerCreateForm',
        65: '/api/manager/setting/mobile/getBannerEditForm?id={0}',

    },
    simpleBtn: {
        1: {
            dialogId: 47,
            submitUrl: '/api/manager/sale/addIntroduceParts',
        },
        2: {
            dialogId: 48,
            submitUrl: '/api/manager/sale/addBuyerRecommend',
        },
        3: {
            dialogId: 52,
            submitUrl: '/api/manager/sale/sku/create',
        },
        4: {
            dialogId: 54,
            submitUrl: '/api/manager/sale/order/refund',
        },
        5: {
            dialogId: 58,
            submitUrl: '/api/manager/sale/updateLimitAgent',
        },
        6: {
            submitUrl: '/api/manager/profit/share/aim/create',
            dialogId: 59,
        },
        7: {
            submitUrl: '/api/manager/enum/create',
            dialogId: 61,
        },
        8: {
            submitUrl: '/api/manager/profit/share/bind/create',
            dialogId: 62,
        },
        9: {
            submitUrl: '/api/manager/sale/order/refundAudit',
            dialogId: 63,
        },
        10: {
            dialogId: 64,
            submitUrl: '/api/manager/setting/mobile/createBanner',
        },
    },
    table: {
        1: {
            queryForm: 13,
            list: '/api/manager/sale/user/list',
            update: '/api/manager/sale/user/update',
            updateDialog: 51,
            updateKey: 'userId',
        },
        2: {
            dialogId: 2,
            submitUrl: '/api/manager/enum/create?t=1',
        },
        3: {
            queryForm: 1,
            list: '/api/manager/hot/spring/list',
            create: '/api/manager/hot/spring/create',
            update: '/api/manager/hot/spring/update',
            createDialog: 4,
            updateDialog: 5,
            updateKey: 'id',
        },
        4: {
            queryForm: 2,
            list: '/api/manager/guest/room/style/list',
            create: '/api/manager/guest/room/style/create',
            update: '/api/manager/guest/room/style/update',
            createDialog: 6,
            updateDialog: 7,
            updateKey: 'id',
            delete: '/api/manager/guest/room/style/delete',
            deleteKey: 'id',
        },
        5: {
            queryForm: 13,
            list: '/api/manager/sale/user/list',
            update: '/api/manager/sale/user/update',
            updateDialog: 51,
            updateKey: 'userId',
        },
        6: {
            queryForm: 3,
            list: '/api/manager/guest/room/list',
            create: '/api/manager/guest/room/create',
            update: '/api/manager/guest/room/update',
            createDialog: 8,
            updateDialog: 9,
            updateKey: 'id',
        },
        11: {
            list: '/api/manager/enum/list',
            update: '/api/manager/enum/update',
            updateDialog: 60,
            updateKey: 'id',
            delete: '/api/manager/enum/delete',
            deleteKey: 'id',
        },
        13: {
            list: '/api/manager/setting/mobile/bannerList',
            updateDialog: 65,
            updateKey: 'id',
            update: '/api/manager/setting/mobile/updateBanner',
            delete: '/api/manager/setting/mobile/deleteBanner',
            deleteKey: 'id',
        },
    }


}

export default base;