import "core-js/modules/es.array.push.js";
import api from "@/api";
export default {
  name: "AsideMenu",
  data() {
    return {
      menus: [],
      openedIds: ["1", "2", "8", "9", "12"]
    };
  },
  mounted() {
    api.getMenus().then(ret => {
      this.menus = ret;
    });
  },
  methods: {
    goto(link) {
      if (!link) {
        return;
      }
      this.$router.push(link);
    }
  }
};