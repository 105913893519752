import BatterTableDataView from "@/components/BatterTableDataView.vue";
export default {
  name: "GuestRoomView",
  components: {
    BatterTableDataView
  },
  data() {
    return {};
  },
  methods: {
    gotoEdit(d) {
      window.open('/html/edit/' + d, '_blank');
    },
    previewHtml(d) {
      window.open('/html/preview/' + d, '_blank');
    },
    init() {}
  },
  mounted() {
    this.init();
  }
};