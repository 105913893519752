import "core-js/modules/es.array.push.js";
import api from "@/api";
import { mapMutations } from "vuex";
import md5 from 'js-md5';
export default {
  name: "LoginPage",
  data() {
    return {
      loginModel: {
        account: "",
        password: "",
        captcha: ""
      },
      validateRule: {
        account: {
          required: true,
          message: '必填项'
        },
        password: {
          required: true,
          message: '必填项'
        },
        captcha: {
          required: true,
          message: '必填项'
        }
      },
      captchaToken: 'x'
    };
  },
  methods: {
    ...mapMutations(['updateUser']),
    queryCaptchaToken() {
      api.getLoginCaptchaToken().then(ret => {
        this.captchaToken = ret;
      });
    },
    verifyLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.doLogin();
        }
      });
    },
    doLogin() {
      api.login({
        phone: this.loginModel.account,
        password: md5(this.loginModel.password),
        captcha: this.loginModel.captcha,
        token: this.captchaToken,
        channel: 0
      }).then(ret => {
        localStorage.setItem('access_token', ret.accessToken);
        this.updateUser(ret);
        // 取到原页面路径
        let url = this.$route.query.redirect;
        // 跳转回原页面
        this.$router.push({
          path: decodeURIComponent(url ? url : ret.index)
        });
      }).catch(err => {
        console.log('login err', err);
      });
    }
  },
  mounted() {
    this.queryCaptchaToken();
  }
};