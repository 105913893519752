import api from "@/api";
import { ElMessage } from "element-plus";
export default {
  name: "OssUploader",
  props: {
    allowedFileTypes: {
      type: Array,
      default: () => {
        return ['jpeg', 'png', 'jpg', 'gif', 'mp4', 'zip'];
      }
    }
  },
  methods: {
    beforeUpload(file) {
      if (this.allowedFileTypes[0] == '*') {
        return true;
      }
      let allowed = false;
      for (let t of this.allowedFileTypes) {
        if (file.name.endsWith(t)) {
          allowed = true;
        }
      }
      if (!allowed) {
        console.log('file.type', file.type, file.name);
        ElMessage.error('仅支持' + this.allowedFileTypes.join(',') + '格式');
        return false;
      }
      return true;
    },
    initOssClint() {
      if (this.ossInitState) {
        console.log('OSS已经完成初始化!');
        return;
      }
      this.ossInitState = true;
      let OSS = require('ali-oss');
      api.getOssKeySecret().then(cfg => {
        this.ossClient = new OSS({
          region: cfg.region,
          accessKeyId: cfg.accessKeyId,
          accessKeySecret: cfg.accessKeySecret,
          stsToken: cfg.securityToken,
          bucket: cfg.bucket,
          endpoint: cfg.endpoint,
          secure: true
        });
        this.ossDomain = cfg.domain;
        this.ossFolder = cfg.folder;
      });
    },
    handleImageUploadSuccess(url, r) {
      ElMessage.success('上传成功');
      if (this.ossDomain) {
        url = this.ossDomain + '/' + r.name;
      }
      this.$emit('onUploaded', url);
      this.$refs.iceUploader.clearFiles();
    },
    guid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
          v = c === 'x' ? r : r & 0x3 | 0x8;
        return v.toString(16);
      });
    },
    uploadRequest(options) {
      try {
        ElMessage.info('正在上传,请稍后');
        let file = options.file; // 拿到 file
        let fileType = file.name.substr(file.name.lastIndexOf('.'));
        let fileName = (this.ossFolder ? this.ossFolder + '/' : 'user-upload/') + this.guid() + fileType;
        // 上传文件,这里是上传到OSS的 uploads文件夹下
        this.ossClient.put(fileName, file).then(res => {
          if (res.res.statusCode === 200) {
            options.onSuccess(res);
          } else {
            options.onError("上传失败");
          }
        });
      } catch (e) {
        options.onError("上传失败");
      }
    }
  },
  mounted() {
    this.initOssClint();
  }
};