import * as echarts from 'echarts';
import elementResizeDetectorMaker from "element-resize-detector";
const erd = elementResizeDetectorMaker();
export default {
  name: "PieChart",
  props: {
    id: {
      type: String,
      default: "chart"
    },
    pieData: {
      type: Object,
      default: () => {
        return {
          name: "chartId",
          title: "加载中",
          data: []
        };
      }
    }
  },
  watch: {
    pieData() {
      this.renderChart();
    }
  },
  methods: {
    renderChart() {
      if (!this.pieData.data) {
        return;
      }
      const titles = this.getTitles(this.pieData.data);
      const series = this.getSeries(this.pieData.data);
      const option = {
        "title": titles,
        "tooltip": {
          show: true,
          formatter: params => {
            return params.data.hint;
          },
          position: function (point, params, dom, rect, size) {
            let x = 0;
            let y = 0;
            let pointX = point[0];
            let pointY = point[1];
            let boxWidth = size.contentSize[0];
            let boxHeight = size.contentSize[1];
            if (boxWidth > pointX) {
              x = 5;
              y -= 15;
            } else {
              x = pointX - boxWidth - 15;
            }
            if (boxHeight + 20 > pointY) {
              y = pointY + 15;
            } else if (boxHeight > pointY) {
              y = 5;
            } else {
              y = y + pointY - boxHeight;
            }
            return [x, y];
          },
          //提示框超长换行
          extraCssText: 'width:250px; white-space:pre-wrap'
        },
        "series": series
      };
      let pie = document.getElementById(this.id);
      let exist = echarts.getInstanceByDom(pie);
      if (exist) {
        console.log(this.id, '销毁已存在的');
        exist.dispose();
      }
      let chart = echarts.init(pie);
      chart.setOption(option);
    },
    resize() {
      this.$nextTick(() => {
        this.renderChart();
      });
    },
    switchChart() {
      this.$emit('switchChart');
    },
    getTitles(data) {
      const each = 100 / data.length;
      return data.map((d, i) => {
        return {
          text: d.title,
          x: 'center',
          y: i * each + '%',
          textStyle: {
            fontSize: 14,
            color: '#1D2129'
          }
        };
      });
    },
    getSeries(data) {
      const each = 100 / data.length;
      return data.map((d, i) => {
        return {
          type: 'pie',
          radius: ['30%', '50%'],
          top: i * each + '%',
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2
          },
          width: "auto",
          height: each + '%',
          data: d.data
        };
      });
    }
  },
  mounted() {
    erd.listenTo(this.$el, this.resize.bind(this));
  },
  unmounted() {
    erd.uninstall(this.$refs.canvas);
  }
};