import axios from "../utils/http";
import path from "./path"

const stringFormat = (formatted, args) => {
    for (let i = 0; i < args.length; i++) {
        let regexp = new RegExp('\\{' + i + '\\}', 'gi')
        formatted = formatted.replace(regexp, args[i])
    }
    return formatted
}
const api = {

    getMenus() {
        return axios.get(path.baseUrl + path.menus)
    },
    getUserInfo(accessToken) {
        console.log('getUserInfo accessToken', accessToken);
        return axios.get(path.baseUrl + path.userInfo)
    },
    getLoginCaptchaToken() {
        return axios.get(path.baseUrl + path.loginCaptchaToken)
    },
    login(data) {
        return axios.post(path.baseUrl + path.login, data)
    },
    logout() {
        return axios.get(path.baseUrl + path.logout)
    },
    getQueryForm(formId, query) {
        return axios.get(path.baseUrl + path.queryForm[formId], {params: query})
    },
    getOssKeySecret() {
        return axios.get(path.baseUrl + path.getOssKeySecret);
    },
    get(url, query) {
        if (query) {
            return axios.get(path.baseUrl + url, {params: query})
        }
        return axios.get(path.baseUrl + url)
    },
    post(url, data) {
        return axios.post(path.baseUrl + url, data)
    },
    getDialogForm(dataId, bindId) {
        if (bindId != null) {
            return axios.get(path.baseUrl + stringFormat(path.dialogs[dataId], [bindId]))
        }
        return axios.get(path.baseUrl + path.dialogs[dataId])
    },
    createMessage(data) {
        return axios.post(path.baseUrl + path.createMessage, data);
    },
    updateMessage(data) {
        return axios.post(path.baseUrl + path.updateMessage, data);
    },
    deleteMessage(messageId) {
        return axios.get(path.baseUrl + stringFormat(path.deleteMessage, [messageId]))
    },
    getNewsDetailData(newsId) {
        return axios.get(path.baseUrl + stringFormat(path.messageDetail, [newsId]))
    },
    getAdTableData(query) {
        return axios.get(path.baseUrl + path.adList, {params: query});
    },
    createAd(data) {
        return axios.post(path.baseUrl + path.createAd, data);
    },
    updateAd(data) {
        return axios.post(path.baseUrl + path.updateAd, data);
    },
    deleteAd(adId) {
        return axios.get(path.baseUrl + stringFormat(path.deleteAd, [adId]))
    },
    queryChannelData(query) {
        return axios.get(path.baseUrl + path.queryChannelData, {params: query});
    },
    getMyInfo() {
        return axios.get(path.baseUrl + path.getMyInfo);
    },
    updateMyInfo(data) {
        return axios.post(path.baseUrl + path.updateMyInfo, data);
    },
    createManager(data) {
        return axios.post(path.baseUrl + path.createManager, data);
    },
    updateManager(data) {
        return axios.post(path.baseUrl + path.updateManager, data);
    },
    deleteManager(adminId) {
        return axios.get(path.baseUrl + stringFormat(path.deleteManager, [adminId]));
    },
    getManagerListData(query) {
        return axios.get(path.baseUrl + path.getManagerListData, {params: query});
    },
    promoterPromotionStatisticQuery(query) {
        return axios.get(path.baseUrl + path.promoterPromotionStatisticQuery, {params: query});
    },
    deleteIntroduceContent(contentId) {
        return axios.get(path.baseUrl + stringFormat(path.deleteIntroduceContent, [contentId]));
    },
    createIntroContent(data) {
        return axios.post(path.baseUrl + path.createIntroContent, data);
    },
    updateEnumValueEnableState(dataId, value) {
        return axios.get(path.baseUrl + stringFormat(path.updateEnumValueEnableState, [dataId, value]))
    },
    updateEnumValueSortIndex(query) {
        return axios.get(path.baseUrl + path.updateEnumValueSortIndex, {params: query});
    },
    getEnumOptionList(query) {
        return axios.get(path.baseUrl + stringFormat(path.getEnumOptionList, [query.type]), {params: query});
    },
    updateBannerEnableState(id) {
        return axios.get(path.baseUrl + stringFormat(path.updateBannerEnableState, [id]));
    },
    getConfigList(query) {
        return axios.get(path.baseUrl + path.getConfigList, {params: query});
    },
    createOrUpdateBaseConfig(data) {
        return axios.post(path.baseUrl + path.createOrUpdateBaseConfig, data);
    },
    getEnumValueById(id) {
        return axios.get(path.baseUrl + stringFormat(path.getEnumValueById, [id]));
    },
    updateEnumValue(data) {
        return axios.post(path.baseUrl + path.updateEnumValue, data);
    },
    createEnumValue(data) {
        return axios.post(path.baseUrl + path.createEnumValue, data);
    },
}

export default api;