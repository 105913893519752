import BatterTableDataView from "@/components/BatterTableDataView.vue";
import ButtonDialog from "@/components/ButtonDialog.vue";
import api from "@/api";
export default {
  name: "BannerSettingView",
  components: {
    ButtonDialog,
    BatterTableDataView
  },
  data() {
    return {};
  },
  methods: {
    changeEnableState(id) {
      api.updateBannerEnableState(id).then(() => {
        this.$refs.bannerTable.fetchData();
      });
    },
    init() {}
  },
  mounted() {
    this.init();
  }
};