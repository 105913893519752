import api from "@/api";
export default {
  name: "CommonHtmlPreviewView",
  components: {},
  data() {
    return {
      htmlId: this.$route.params.htmlId,
      footerId: this.$route.query.footer,
      pageId: this.$route.query.page,
      title: null,
      html: null,
      footer: null,
      page: null,
      siteName: null
    };
  },
  methods: {
    queryStyleAndSet() {
      this.$refs.renderArea.style.setProperty('--table-deep-bg-color', '#9595A7');
      this.$refs.renderArea.style.setProperty('--table-light-bg-color', '#f2f2f8');
    },
    replaceHtml(html) {
      return html.replaceAll('$DOMAIN$', `<a href="#" class="domain-link">${this.siteName ? this.siteName : '站点名称'}</a>`);
    },
    addSubTitleClassToSpans(divId) {
      // 获取指定的 div 元素
      const div = document.getElementById(divId);
      if (div) {
        // 查找该 div 下所有的 span
        const spans = div.querySelectorAll('span');
        spans.forEach(span => {
          // 获取每个 span 的样式
          const computedStyle = window.getComputedStyle(span);
          // 检查 font-size 是否为 16px 且包含 strong 标签
          if (computedStyle.fontSize === '16px' && span.querySelector('strong')) {
            // 添加 'sub-title' 类
            span.classList.add('sub-title');
          }
        });
      }
    },
    init() {
      let thiz = this;
      this.queryStyleAndSet();
      api.getEnumValueById(this.htmlId).then(ret => {
        this.title = ret.label;
        this.extra = ret.extra;
        if (ret.extra.configValue) {
          api.get(ret.extra.configValue, {
            ice_plain: true
          }).then(ctn => {
            thiz.html = this.replaceHtml(ctn);
            setTimeout(() => {
              thiz.addSubTitleClassToSpans('content-render');
            }, 200);
          });
        } else {
          thiz.html = '此页面无数据';
        }
      });
    }
  },
  mounted() {
    this.init();
  }
};