import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createSlots as _createSlots, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  class: "paginationNumber"
};
const _hoisted_2 = {
  class: "paginationNumber"
};
const _hoisted_3 = {
  class: "paginationNumber"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_DynaQueryForm = _resolveComponent("DynaQueryForm");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_table_column = _resolveComponent("el-table-column");
  const _component_el_table = _resolveComponent("el-table");
  const _component_el_pagination = _resolveComponent("el-pagination");
  const _component_el_config_provider = _resolveComponent("el-config-provider");
  const _directive_loading = _resolveDirective("loading");
  return _openBlock(), _createElementBlock(_Fragment, null, [$props.queryForm ? (_openBlock(), _createBlock(_component_el_row, {
    key: 0
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 24
    }, {
      default: _withCtx(() => [_createVNode(_component_DynaQueryForm, {
        "form-id": $props.formId,
        "init-query": $props.initQuery,
        onOnQuery: $options.onQuery,
        onOnReset: $options.onResetQuery
      }, null, 8, ["form-id", "init-query", "onOnQuery", "onOnReset"])]),
      _: 1
    })]),
    _: 1
  })) : _createCommentVNode("", true), _renderSlot(_ctx.$slots, "beforeTable"), _createVNode(_component_el_row, null, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 24
    }, {
      default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_table, {
        style: {
          "width": "100%"
        },
        data: $data.tableData.list,
        "header-row-style": {
          color: 'black'
        },
        "header-cell-style": {
          background: '#F0F0F0'
        }
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.tableData.header, (h, i) => {
          return _openBlock(), _createBlock(_component_el_table_column, {
            key: h.name,
            sortable: !!h.sortable,
            prop: h.name,
            label: h.label,
            "show-overflow-tooltip": i + 1 < $data.tableData.header.length /** 最后一栏不省略 */,
            width: h.width
          }, _createSlots({
            _: 2
          }, [h.hasSlot ? {
            name: "default",
            fn: _withCtx(scope => [_renderSlot(_ctx.$slots, h.name, {
              rowData: scope.row[h.name],
              rowId: scope.row['id']
            })]),
            key: "0"
          } : undefined]), 1032, ["sortable", "prop", "label", "show-overflow-tooltip", "width"]);
        }), 128))]),
        _: 3
      }, 8, ["data"])), [[_directive_loading, $data.tableDataLoading]])]),
      _: 3
    }), _createVNode(_component_el_col, {
      span: 24
    }, {
      default: _withCtx(() => [_createVNode(_component_el_config_provider, {
        locale: $setup.locale
      }, {
        default: _withCtx(() => [_createVNode(_component_el_pagination, {
          background: "",
          "current-page": $data.page,
          "page-sizes": [10, 20, 30, 40, 50, 100],
          onSizeChange: $options.onSizeChange,
          onCurrentChange: $options.gotoPage,
          layout: "sizes,slot,->,prev, pager, next,jumper",
          total: $data.total
        }, {
          default: _withCtx(() => [_createElementVNode("span", null, [_createTextVNode("当前第"), _createElementVNode("span", _hoisted_1, _toDisplayString(($data.page - 1) * $data.pageSize + $data.tableData.list.length), 1), _createTextVNode("/"), _createElementVNode("span", _hoisted_2, _toDisplayString($data.total), 1), _createTextVNode("条，共"), _createElementVNode("span", _hoisted_3, _toDisplayString(parseInt($data.total / $data.pageSize) + 1), 1), _createTextVNode("页")])]),
          _: 1
        }, 8, ["current-page", "onSizeChange", "onCurrentChange", "total"])]),
        _: 1
      }, 8, ["locale"])]),
      _: 1
    })]),
    _: 3
  })], 64);
}