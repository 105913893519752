import api from "@/api";
import DataTable from "@/components/DataTable";
import DialogView from "@/components/DialogView";
import { ElConfigProvider } from 'element-plus';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
export default {
  name: "AdminView",
  components: {
    ElConfigProvider,
    DialogView,
    DataTable
  },
  setup() {
    return {
      locale: zhCn
    };
  },
  data() {
    return {
      editDialogVisible: false,
      editDialogDataId: 0,
      editBindDataId: 0,
      detailDialogVisible: false,
      detailBindDataId: 0,
      detailDialogDataId: 0,
      createDialogVisible: false,
      createDialogDataId: 0,
      query: {},
      form: {},
      total: 200,
      page: 1,
      pageSize: 10,
      tableDataLoading: false,
      tableData: {
        title: "...",
        header: [],
        list: [],
        textStyleMapping: {},
        textMapping: {}
      }
    };
  },
  mounted() {
    this.fetchUserData();
  },
  methods: {
    gotoPage(e) {
      this.page = e;
      this.fetchUserData();
    },
    onSizeChange(e) {
      this.page = 1;
      this.pageSize = e;
      this.fetchUserData();
    },
    onResetQuery() {
      this.query = {};
      this.fetchUserData();
    },
    onUserQuery(query) {
      this.query = query;
      this.fetchUserData();
    },
    fetchUserData() {
      this.tableDataLoading = true;
      this.query['page'] = this.page - 1;
      this.query['pageSize'] = this.pageSize;
      api.getManagerListData(this.query).then(ret => {
        this.tableDataLoading = false;
        this.tableData = ret;
        this.total = ret.total;
        this.page = ret.page + 1;
        this.pageSize = ret.pageSize;
      });
    },
    deleteManager(userId) {
      api.deleteManager(userId).then(() => {
        this.fetchUserData();
      });
    },
    updateManager(data) {
      data.managerId = this.editBindDataId;
      api.updateManager(data).then(() => {
        this.editDialogVisible = false;
        this.editBindDataId = 0;
        this.editBindDataId = 0;
        this.fetchUserData();
      });
    },
    createManager(data) {
      api.createManager(data).then(() => {
        this.createDialogVisible = false;
        this.createDialogDataId = 0;
        this.fetchUserData();
      });
    }
  }
};