import api from "@/api";
import { ElMessage } from "element-plus";
import OssUploader from "@/components/OssUploader.vue";
import DialogCustomView from "@/components/DialogCustomView.vue";
import EmdEnumValueListView from "@/views/system/EmdEnumValueListView.vue";
import ButtonDialog from "@/components/ButtonDialog.vue";
export default {
  name: "GuestRoomConfigView",
  components: {
    ButtonDialog,
    EmdEnumValueListView,
    DialogCustomView,
    OssUploader
  },
  data() {
    return {
      infoList: [],
      configList: [],
      serviceCatalogList: [],
      siteName: null,
      previewImg: null,
      previewImgVisible: false
    };
  },
  methods: {
    gotoEdit(d) {
      window.open('/html/edit/' + d.id, '_blank');
    },
    previewHtml(d) {
      window.open('/html/preview/' + d.id, '_blank');
    },
    previewCfg(cfg) {
      this.previewImg = cfg.extra.configValue;
      this.previewImgVisible = true;
    },
    isImg(u) {
      if (!u) {
        return false;
      }
      return !!(u.startsWith('http') && (u.toLowerCase().endsWith('jpg') || u.toLowerCase().endsWith('jpeg') || u.toLowerCase().endsWith('png') || u.toLowerCase().endsWith('gif')));
    },
    init() {
      api.getConfigList({
        type: 'GUEST_ROOM_INFO'
      }).then(ret => {
        this.infoList = ret;
      });
      api.getConfigList({
        type: 'GUEST_ROOM_CONFIG'
      }).then(ret => {
        this.configList = ret;
      });
      api.getConfigList({
        type: 'GUEST_ROOM_SERVICE_CATALOG'
      }).then(ret => {
        this.serviceCatalogList = ret;
      });
    },
    updateWithUrl(url, cfg) {
      cfg.configValue = url;
      this.updateConfig(cfg);
    },
    updateConfig(cfg) {
      api.createOrUpdateBaseConfig(cfg).then(() => {
        ElMessage.success('更新成功');
        this.init();
      });
    }
  },
  mounted() {
    this.init();
  }
};