import DataTable from "@/components/DataTable";
import zhCn from 'element-plus/es/locale/lang/zh-cn';
export default {
  name: "TableDataView",
  components: [DataTable],
  setup() {
    return {
      locale: zhCn
    };
  },
  props: {
    formId: {
      type: Number,
      default: 0
    },
    queryForm: {
      type: Boolean,
      default: false
    },
    listFunction: {
      type: Function,
      default: null
    },
    initQuery: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  watch: {
    initQuery() {
      this.fetchData();
      this.$emit('onInitQuery', this.initQuery);
    }
  },
  methods: {
    gotoPage(e) {
      this.page = e;
      this.fetchData();
    },
    onSizeChange(e) {
      this.page = 1;
      this.pageSize = e;
      this.fetchData();
    },
    onQuery(data) {
      this.query = data;
      this.fetchData();
      this.$emit('onQuery', data);
    },
    onResetQuery() {
      this.query = {};
      this.fetchData();
      this.$emit('onResetQuery', {});
    },
    fetchData() {
      this.tableDataLoading = true;
      for (let k of Object.keys(this.initQuery)) {
        if (!this.query[k]) {
          this.query[k] = this.initQuery[k];
        }
      }
      this.query['page'] = this.page - 1;
      this.query['pageSize'] = this.pageSize;
      this.listFunction(this.query).then(ret => {
        this.tableDataLoading = false;
        this.tableData = ret;
        this.total = ret.total;
        this.page = ret.page + 1;
        this.pageSize = ret.pageSize;
      }).catch(() => {
        this.tableDataLoading = false;
      });
    }
  },
  data() {
    return {
      query: {},
      form: {},
      total: 0,
      page: 1,
      pageSize: 10,
      tableDataLoading: false,
      tableData: {
        title: "...",
        header: [],
        list: [],
        textStyleMapping: {},
        textMapping: {}
      }
    };
  },
  mounted() {
    this.fetchData();
    this.$emit('onInitQuery', this.initQuery);
  }
};